import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Obfuscate from 'react-obfuscate'

import SEO from '../components/seo'
import Layout from '../components/layout'
import BlockContentSanity from '../components/BlockContentSanity/BlockContentSanity'
import LeafletMap from '../components/LeafletMap/LeafletMap'

import { forScreen } from '../styles-global/styled-components/styled-responsive'
import Partners from '../components/aboutCinema/partners'

const Okinie = ({ data }) => {
  const aboutCinemaBody = data?.sanityAboutCinema?.body
  const priceListBody = data?.sanityPriceList?.body
  const partners = data?.sanityAboutCinema?.partners
  const customPartnersTitle = data?.sanityAboutCinema?.customPartnersTitle

  return (
    <Layout>
      <SEO title="O kinie" />
      <main className="container-xxl">
        <h1>O kinie</h1>
        <Wrapper>
          <div id="aboutUs">
            <h2>Informacje o kinie</h2>
            <BlockContentSanity blocks={aboutCinemaBody} />
          </div>
          <div id="priceList" className="priceList">
            <h2>Cennik biletów</h2>
            <BlockContentSanity blocks={priceListBody} />
          </div>
          <Partners
            partners={partners}
            customPartnersTitle={customPartnersTitle}
            className="partners"
            id="partners"
          />
          <div id="findUs" className="findUs">
            <h2>Jak nas znaleźć?</h2>

            <LeafletMap
              showMarker
              shownPopup
              position={[50.78273, 17.06715]}
              zoom={17}
              googleMapsNavigateToUrl="https://www.google.com/maps/dir//Kino+GRA%C5%BBYNA,+Adama+Mickiewicza+2,+57-100+Strzelin/@50.78254,17.066533,19z/data=!4m16!1m6!3m5!1s0x470fd53cb3222857:0x9ce5e0c76ac51ef6!2sKino+GRA%C5%BBYNA!8m2!3d50.7827975!4d17.0669469!4m8!1m0!1m5!1m1!1s0x470fd53cb3222857:0x9ce5e0c76ac51ef6!2m2!1d17.0669466!2d50.7827975!3e2"
            >
              <MarkerContent />
            </LeafletMap>
          </div>
        </Wrapper>
      </main>
    </Layout>
  )
}

// content to be shown on popup on map marker
const MarkerContent = () => (
  <StyledMarker>
    <h3>Kino Grażyna Strzelin </h3>
    <address>
      <span className="block">ul. Adama Mickiewicza 2 </span>
      <span className="block">57-100 Strzelin </span>
      <span className="block">
        <Obfuscate tel="+48 575 519 155" />
      </span>
      <span className="block">
        <Obfuscate email="biuro@kinostrzelin.pl" />
      </span>
    </address>
  </StyledMarker>
)

const StyledMarker = styled.div`
  /* overwrite leaflet color */
  color: var(--textMainColor);
`

const Wrapper = styled.div`
  display: grid;

  margin-bottom: 1rem;

  /* RESPONSIVE */
  ${forScreen.lg`
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;

    .findUs, .partners{
      grid-column: 1 / -1;
    }
  `}
`

export const query = graphql`
  query {
    sanityAboutCinema(_id: { eq: "aboutCinema" }) {
      body: _rawBody(resolveReferences: { maxDepth: 10 })
      customPartnersTitle
      partners {
        title
        partnerUrl
        _key
        asset {
          id
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityPriceList(_id: { eq: "priceList" }) {
      body: _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default Okinie
