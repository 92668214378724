import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import styled from 'styled-components'

/* 
    Component for showing Leaflet map

    When prop showMarker passed (true) - shows marker for position with popup

    If prop shownPopup passed (true ) - popup is opened when map shows


    In the popup there are shown children passed to the component

    There is also an external link to navigate in Open Street Map to the given position

    googleMapsNavigateToUrl is optional - it has to contain url to navigate in Google Maps
    (if none - link not shown)

    based on documentation https://www.gatsbyjs.org/packages/gatsby-plugin-react-leaflet/
    my notes: https://www.notion.so/jurekskowron/Dodawanie-mapy-React-Leaflet-148e140238dc4fd098f8f51a0a94d42e
    my working example: https://github.com/jurek2006/gatsby-map-leaflet-example/blob/master/src/components/MyMap.js

*/

export default class LeafletMap extends Component {
  static defaultProps = {
    position: [51, -1],
    zoom: 13,
  }

  // needed for 'auto-showing' popup
  openPopup(marker) {
    if (marker && marker.leafletElement) {
      window.setTimeout(() => {
        marker.leafletElement.openPopup()
      })
    }
  }

  render() {
    const {
      position,
      zoom,
      googleMapsNavigateToUrl,
      showMarker,
      shownPopup,
      children,
    } = this.props

    if (typeof window !== 'undefined') {
      return (
        <StyledMap center={position} zoom={zoom}>
          <TileLayer
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {showMarker && (
            <Marker position={position} ref={shownPopup ? this.openPopup : ''}>
              (
              <Popup>
                {children}
                <a
                  href={`https://www.openstreetmap.org/directions?from=&to=${position[0]}%2C${position[1]}#map=${zoom}/${position[0]}/${position[1]}`}
                >
                  Wyznacz trasę w Open Street Map
                </a>
                <br />
                {googleMapsNavigateToUrl &&
                  googleMapsNavigateToUrl.length > 0 && (
                    <a href={googleMapsNavigateToUrl}>
                      Wyznacz trasę w Google Maps
                    </a>
                  )}
              </Popup>
              )
            </Marker>
          )}
        </StyledMap>
      )
    }

    return null
  }
}

// We have to add height & width to the map
const StyledMap = styled(Map)`
  height: 400px;
  width: 100%;
`
