import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import PropTypes from 'prop-types'

/* 
  Component which shows section with partners logos (and links if specified)
  Shown if there is any partner item defined in Sanity
*/

const Partners = ({ partners, customPartnersTitle, className, id }) => {
  if (partners && partners.length > 0) {
    return (
      <div id={id} className={className}>
        <h2>
          {customPartnersTitle && customPartnersTitle.length > 0
            ? customPartnersTitle
            : 'Partnerzy'}
        </h2>
        <StyledPartners className="partners">
          {partners &&
            partners.length > 0 &&
            partners.map(partner => (
              <PartnerItem partner={partner} key={partner._key} />
            ))}
        </StyledPartners>
      </div>
    )
  }

  return false
}

const PartnerItem = ({
  partner: {
    title,
    partnerUrl,
    asset: { fluid },
  },
}) => {
  // TODO - add url validation ?
  if (partnerUrl && partnerUrl.length > 0) {
    return (
      <a href={partnerUrl} className="partnerItem partnerItem--link">
        <Img
          fluid={fluid}
          alt={title}
          className="partnerItem__image"
          imgStyle={{ objectFit: 'contain' }}
        />
      </a>
    )
  }

  PartnerItem.propTypes = {
    partner: PropTypes.shape({
      title: PropTypes.string.isRequired,
      partnerUrl: PropTypes.string,
      asset: PropTypes.object.isRequired,
    }),
  }

  return (
    <div className="partnerItem">
      <Img
        fluid={fluid}
        alt={title}
        className="partnerItem__image"
        imgStyle={{ objectFit: 'contain' }}
      />
    </div>
  )
}

Partners.propTypes = {
  partners: PropTypes.array,
  customPartnersTitle: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
}

export default Partners

// ----------------------------------------------------------------------------------

const StyledPartners = styled.div`
  /* item space is set bigger than image inside - image is scaled on hover */
  --partnerItemSpaceSize: 200px;
  --partnerItemImgDefaultSize: 180px;
  --partnerItemImgHoverScale: 1.03;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;

  .partnerItem {
    width: var(--partnerItemSpaceSize);
    height: var(--partnerItemSpaceSize);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
  }

  .partnerItem--link {
    transition: var(--buttonTransition);
    border-bottom: 2px solid var(--aboutCinemaNonHoverColor);

    &:hover {
      text-decoration: none;
      border-color: inherit;
      border-bottom: 2px solid var(--aboutCinemaHoverColor);
      transition: var(--buttonTransition);

      .partnerItem__image {
        transform: scale(var(--partnerItemImgHoverScale));
      }
    }
  }

  .partnerItem__image {
    transition: var(--buttonTransition);
    width: var(--partnerItemImgDefaultSize);
    max-height: var(--partnerItemImgDefaultSize);
  }
`
